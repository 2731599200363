.addFeed {
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  align-items: center;
  height: 350px;

  .container {
    display: grid;
    grid-row-gap: 20px;
  }
}
