@use "sass:math";
@import "~global/styles";

.maintenanceTable {
  $dotSize: 8px;

  overflow-x: auto;

  table {
    th:first-of-type {
      padding-left: $dotSize * 2.5 !important;
    }

    td {
      height: 34px;
      padding: 0 $dotSize !important;
    }
  }

  .unitName {
    padding-left: $dotSize * 1.5;

    .greenDot {
      background-color: $primary-green;
      border: 1px solid var(--color--gray-bg);
      border-radius: 50%;
      box-sizing: content-box;
      display: inline-block;
      height: $dotSize;
      margin-left: -$dotSize * 1.5;
      margin-right: math.div($dotSize, 4);
      width: $dotSize;
    }
  }
}
