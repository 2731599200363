@import "~global/styles";

.brikkContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  padding: 32px 20px 20px;
  text-align: center;
  font-size: $font-size-medium-xs;
  backdrop-filter: blur(10px);

  &.center {
    justify-content: center;
  }

  .error {
    text-align: center;
  }

  .loading {
    width: 80%;
    margin: 1em auto;

    .line {
      height: 15px !important;
      margin-bottom: 15px !important;
      background-color: var(--color--primary-bg) !important;
      color: var(--color--primary-text) !important;

      &:before, &:after {
        height: 15px !important;
      }
    }
  }
}
