@import "~global/styles";


.selectedArticlePreview {
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 37px;
  padding: 2em 1em;
  background-color: #fff;
  border-radius: $border-radius-small;
}

.selectedArticle {
  width: 100%;
  max-height: 600px;

  @include for-s-laptop-and-less {
    max-height: none;
  }

  .fontButton {
    position: relative;
    bottom: 10px;
  }

  .article {
    display: grid;
    grid-template-rows: repeat(4, min-content);
    grid-row-gap: 10px;
    height: 100%;
    padding: 2em 1em;
    scroll-padding-bottom: 2em;
    border-radius: $border-radius-small;
    overflow-y: auto;

    .articleImage {
      margin: auto;
      width: fit-content;
      height: 250px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .content img, a {
      display: none;
    }

    .readMore a {
      text-decoration: underline;
      display: block;
    }

  }
}
