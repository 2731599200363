@import "~global/styles";

.ItemName{
  color: var(--color--primary-text);

  .greenDot {
    display: inline-block;
    margin-right: 10px;
    width: 8px;
    height: 8px;
    background-color: $primary-green;
    border-radius: 50%;
  }
}