@use "sass:math";
@import "~global/styles";

.ItemName {
  $dotSize: 8px;

  padding-left: $dotSize * 1.5;
  color: var(--color--primary-text);

  .greenDot {
    background-color: $primary-green;
    border: 1px solid var(--color--gray-bg);
    border-radius: 50%;
    box-sizing: content-box;
    display: inline-block;
    height: $dotSize;
    margin-left: -$dotSize * 1.5;
    margin-right: math.div($dotSize, 4);
    width: $dotSize;
  }
}
