.activateAccount {

  .form {
    height: fit-content;
    min-height: 350px;
    min-width: 250px;
    width: 100%;
    padding: 3em 1em;
    background-color: #fff;
  }
}
