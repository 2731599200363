.planOverview {
  display: grid;
  grid-gap: 20px;

  .downloadDocument {
    display: grid;
    width: fit-content;

    .button {
      width: fit-content;
    }
  }
}
