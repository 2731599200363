@import "~global/styles";

.costContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.productActions {
  display: grid;
  grid-gap: 35px;
  width: 100%;

  .costContainer {
    display: flex;
    justify-content: space-between;

    .addItem {
      margin-left: auto;
    }
  }
}
