@import "~global/styles";

.menuItem {
  cursor: pointer;
  padding: 0 41px;

  &:hover {
    background-color: $sidebar-bg-secondary;
  }

  &.active {
    .itemContent:before {
      content: "";
      display: block;
      position: absolute;
      left: -15px;
      height: 20px;
      width: 2px;
      background-color: var(--color--primary);
    }
  }

  .itemContent {
    position: relative;
    display: flex;
    grid-gap: 15px;
    padding: 23px 0;
    border-bottom: 2px solid $sidebar-bg-secondary;

    svg {
      fill: white;
    }
  }
}
