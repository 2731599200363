@import "~global/styles";

.feed {
  display: grid;
  grid-template-columns: 125px 30px;
  overflow: hidden;
  cursor: pointer;

  @include for-s-laptop-and-less {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 20px;
  }

  &:hover {
    & > * {
      color: $dark-gray;
    }

    .deleteFeed {
      display: grid;
    }
  }

  .deleteFeed {
    display: none;
    align-items: center;
    justify-items: start;

    svg > * {
      fill: $dark-gray
    }

    @include for-s-laptop-and-less {
      display: grid;
      justify-items: end;
    }
  }
}
