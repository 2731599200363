@import "~global/styles";

.details {
  display: grid;
  grid-gap: 5px;
}

.specificationContent {
  margin-top: 20px;
  margin-bottom: 210px;

  @include for-s-laptop-and-less {
    margin-bottom: 50px;
  }
}
