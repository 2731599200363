.subtitle {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
