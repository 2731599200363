@import "~global/styles";

.mediumCard {
  min-height: 812px;
  max-width: 1300px;
  width: 100%;
  background-color: var(--color--primary-bg);

  @include for-mobile {
    min-height: 500px;
    max-height: 1100px;
  }
}
