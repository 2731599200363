.form {
  display: grid;
  grid-row-gap: 35px;
}

.lastColumn {
  display: flex !important;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 0;

  button[type="submit"] {
    width: fit-content;
  }
}

.insertNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.insertNumber input {
  margin-left: 10% !important;
}

.submitButton {
  align-self: flex-end;
  margin-top: 25px !important;
}
