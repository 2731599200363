@import '~global/styles';

.img {
  width: 80px;
  margin-left: 20%;

  @include for-mobile {
    width: 60px;
    margin-left: 10%;
  }
}
