@import "~global/styles";

.brikk {
  position: relative;

  &.heartbeat {
    animation: wiggle 0.3s ease infinite;
    cursor: pointer;

    * {
      backdrop-filter: none;
    }
  }

  &.dragged {
    border: 3px dashed $primary-green;
    border-radius: $border-radius-medium;
    animation: none;

    & > * {
      opacity: 0;
    }
  }

  .demo {
    position: absolute;
    top: 80px;
    left: 15px;
    z-index: 3;

    & > * {
      padding-left: 1.2em !important;
      padding-right: 1.2em !important;
    }
  }

  .content {
    width: var(--size--brikk) !important;
    height: var(--size--brikk);
    border-radius: $border-radius-medium !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16) !important;
    overflow: hidden;
    background-color: var(--color--primary-bg) !important;
    color: var(--color--primary-text) !important;

    @include for-mobile {
      // margin: auto !important; should be added once all brikks start using new component
      // width: var(--size--brikk) - 60px !important; should be added once all brikks start using new component

      //below properties should be changed with above
      margin: 0 auto !important;
      width: calc(100vw - #{18px * 2}) !important;
      min-width: 250px !important;
      float: left;
    }
  }
  &.selectedBrikk{
    .content {
        // border: 6px solid  #3A3A3A !important;
        box-shadow: 0 0 0 5px #3A3A3A !important;
      
    }
  }

  &.orderMode {
    .content {
      &:hover {
        // border: 4px solid #3A3A3A !important;
        box-shadow: 0 0 0 4px #3A3A3A !important;
      }
    }
  }

  

}

@keyframes wiggle {
  0% {
    transform: rotateZ(0.5deg);
  }
  50% {
    transform: rotateZ(-0.5deg);
  }
  100% {
    transform: rotateZ(0.5deg);
  }
}
