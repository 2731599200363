@import "~global/styles";

.menu {
  cursor: pointer;
}

.userMenu {
  display: grid;
  align-items: center;
  grid-column-gap: 10px;

  .userText {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 10px;
    align-items: center;
    color: var(--color--header-text);
  }

  .avatar {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
    background: $primary-green;
  }
}

.dropdown {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 10px;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;

  .icon {
    color: var(--color--header-icons) !important;
  }

  &:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
  }

  @include for-tablet-portrait-and-less {
    grid-template-columns: none;
  }

  @include for-mobile {
    display: inline-block;
  }
}

@include for-tablet-portrait-and-less {
  .menuText {
    display: none !important;
  }
}
