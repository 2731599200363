@import "~global/styles";

.languagePicker {
  display: grid;
  grid-gap: 15px;
  justify-items: end;
  padding-right: $side-menu-padding-right;
  padding-top: 30px;

  &.column {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 50% 50%;
  }


  .option {
    display: grid;
    grid-template-columns: 33px 50px;
    grid-gap: 10px;
    align-items: center;
  }
}
