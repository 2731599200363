@import "~global/styles";

.content {
  display: grid;
  text-align: left;

  & > * {
    font-size: $font-size-xs !important;
    color: var(--color--brikk-footer-text) !important;
  }
}
