.errorMessage {
  max-width: 290px;
}

.card {
  box-shadow: none!important;
  width: 100% !important;
  background-color: transparent!important;
}

.profileInfo {
  display: grid;
  text-align: center;

  & > * {
    word-break: break-all;
  }
}

.placeholder {
  height: 30px !important;
  margin-bottom: 30px !important;

  &:before,
  &:after {
    height: 30px !important;
  }
}
