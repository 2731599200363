.input {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: transparent;
  color: var(--color--primary-text);

  &[disabled] {
    color: var(--color--primary-text);
  }

  &::placeholder {
    color: var(--color--primary-text);
    opacity: 0.5;
  }
}

.error {
  border: 1px solid #ff0000 !important;
}

