@import "~global/styles";

  .menuItem {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    svg {
      width: 25px;
    }

    @include for-tablet-portrait-and-less {
        display: none;
      }
      &.disabled {
        color: $light-gray;
        cursor: auto;
      }
  }

  .menuText {
    text-transform: capitalize;
    font-size: 14px;
  }

  @include for-tablet-portrait-and-less {
    .menuText {
      display: none;
    }
  }
