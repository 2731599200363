.column {
  display: flex;
  flex-direction: column;

  .logoLine {
    flex-grow: 1;

    .bankIdLogo {
      width: 75px;
      float: left;
      margin: 0 2rem 1rem 1rem;
    }

    .bankIDSigningLink {
      margin-top: 20px;
    }
  }
  .proceedButton {
    align-self: flex-end;
  }
}
