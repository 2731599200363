@import "~global/styles";

.submit {
    background-color: var(--color--primary) !important;
    color: white !important;

    &.disabled{
      background-color: $dark-gray !important;
   }
}


