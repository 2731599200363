@import "~global/styles";

.framed {
  padding: 15%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-medium;

  img {
    height: auto;
    width: 100%;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.bankIDLogo {
  width: 75px;
  height: 75px;
  object-fit: contain;
}
