@import "~global/styles";

.checkout {
  display: flex;
  width: 30%;
  flex-direction: column;
  background-color: var(--color--gray-bg);
  align-items: center;
  &:focus {
    outline: none;
  }
  @include for-mobile {
    width: 20%;
  }

  .title {
    text-decoration: underline;
    padding-top: 5px;
    @include for-mobile {
      display: none;
    }
  }
}
