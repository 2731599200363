@import "~global/styles";

$imageSize: 78px;

.article {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:first-child {
    border-top-right-radius: $border-radius-small;
    border-top-left-radius: $border-radius-small;
  }

  &:last-child {
    border-bottom-right-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;
  }

  &.rightBorder {
    &:first-child {
      border-top-left-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
    }
  }

  .imageAndFeed {
    display: flex;
    flex-direction: column;

    .image {
      display: flex;
      justify-items: center;
      width: $imageSize;
      height: $imageSize;
      margin-right: 20px;

      img {
        object-fit: cover;
      }
    }

    .feed {
      text-align: center;
      margin-top: 3px;
    }
  }

  .articleInfo {
    text-align: left;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .preview {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      @include for-s-laptop-and-less {
        & {
          -webkit-line-clamp: 4;
        }
      }

      img {
        display: none;
      }
    }

    .date {
      margin-top: 9px;
    }
  }
}
