@import "~global/styles";

.WaterUsageCarousel {
  margin-top: 1rem !important;
  position: relative;
  width: 200px;

  .icon {
    display: grid;
    justify-items: center;
    grid-gap: 5px;

    &:after {
      display: block;
      content: "";
      width: 46px;
      height: 4px;
      background-color: $gray;
    }
  }

  .Slide {

    .SlideWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      margin-top: 1rem;

      .ValueWrapper {
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 3rem;
        line-height: 1em;
        font-weight: bold;
      }

      .LabelWrapper {
        margin-top: 0.5rem;
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: 500;

        span {
          font-size: 0.9rem;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
  }
}
