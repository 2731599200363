@import "~global/styles";

.card {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.18);

  &.lg {
    border-radius: $radius-lg;
  }

  &.transparent {
    background-color: transparent!important;
    border: none;
  }

  &.grey {
    background-color: var(--color--gray-bg);
    border: none;
    border-radius: 0.3rem;
    padding: 1em 1em;
    width: 100%;
  }
}

