.chartWrapper {
  position: relative;

  .loadingOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.faded {
      background-color: rgba(255, 255, 255, 0.7);
    }

    .loadingSpinner {
      transform-origin: center center;
      border: 4px solid #ccc;
      border-bottom-color: #55B336;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      animation: circle-loader 1s infinite ease-in-out;
    }

    @keyframes circle-loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
