.multipleImage {
  display: grid;
  grid-gap: 50px;
  background-color: white;
  border: 1px solid #DEDEDF;
  border-radius: 4px;
  padding: 15px 25px;

  &.disabled {
    opacity: 0.5;
  }

  .images {
    display: grid;
    grid-gap: 5px;

    .imagesList {
      max-height: 124px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .prompt {
    display: grid;
    grid-gap: 17px;

    .imageUpload {
      display: grid;
      justify-content: center;
      position: relative;

      .upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
