@import "~global/styles";

.button {
  display: flex !important;
  justify-items: center;
  align-items: center;
  position: relative;
  width: fit-content !important;
  background-color: transparent !important;
  padding: 0.2em 0.5em !important;
  margin: 0.2em;
  text-decoration: underline !important;
  font-weight: normal !important;
  font-size: 12px !important;
  color: var(--color--brikk-footer-text) !important;

  .text {
    margin-right: 16px;
  }

  .icon {
    position: absolute;
    right: 0;
    width: 16px;
    background-color: transparent !important;
  }
  
}

.disabled {
  pointer-events: none !important;
}
