@import '~global/styles';

.timeWrapper{
    display: flex;
    justify-content: center;

    @media screen and (min-width: 1600px) {
        z-index: 3;
    }

    input{
        font-size: 15px !important;
        border: 1px solid #aeaeae !important;
    }

    li {
        font-size: 15px !important;
    }
}

.timePicker{
    width: auto !important;
    padding: 0 5px 0 2px !important;
}

