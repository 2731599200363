.statusLine {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2px;

  &.small {
     align-items: baseline;  
  }
}
