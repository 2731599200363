@import "~global/styles";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 6px;
  min-width: 100px;
  background-color: white;
  border-radius: 4px 0 0 4px;
  border: 1px solid $gray-200;
}
