.uploadedImage {
  display: grid;
  grid-template-columns: 36px auto auto;
  grid-gap: 15px;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #E1E1E1;

  &:last-child {
    border-bottom: none;
  }

  .image {
    width: 36px;
    height: 36px;
    object-fit: cover;
  }

  .description {
    display: grid;
  }

  .remove {
    justify-self: end;
  }
}
