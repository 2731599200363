@import "~global/styles";

.feedList {
  display: grid;
  grid-row-gap: 45px;
  overflow-y: auto;

  .section {

    .sectionList {
      display: grid;
      grid-gap: 13px;
      margin-top: 14px;
      padding-left: 20px;

      &.scrollable {
        max-height: 250px;
        overflow-y: auto;
      }
    }
  }
}
