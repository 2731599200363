@import "~global/styles";

.modal {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-row-gap: 100px;
  grid-column-gap: 25px;
  justify-content: center;
  padding: 0 20px;

  @include for-s-laptop-and-less {
    grid-template-columns: none;
  }

  @include for-tablet-portrait-and-less {
    padding: 0;
  }

  .feedArticles,
  .selectedArticle {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
