@import "~global/styles";

.textLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  font-weight: $font-weight-bold;
}

.imageBg{
    background-color: $white !important;
}