@import "~global/styles";

.agreement {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  background-color: var(--color--gray-bg);
  box-shadow: 3px 3px 6px $light-gray;
  align-items: center;
  margin: 1rem 0;
  @include for-mobile {
    flex-direction: column;
  }

  .status {
    width: 15%;
    @include for-mobile {
      text-align: center;
      width: 100%;
    }
  }

  .description {
    width: 45%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    @include for-big-laptop {
      width: 75%;
    }
    @include for-mobile {
      width: 100%;
      align-items: center;
      margin: 1rem 0;
    }

    .inline {
      display: flex;
      align-items: baseline;
      .statusIcon {
        display: inline-block;
        margin-left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        &.active {
          background-color: $primary-green;
        }
        &.pending {
          background-color: orange;
        }
        &.inactive {
          background-color: red;
        }
      }

      .paddingRight {
        padding-right: 3px;
      }
    }
  }
  .action {
    text-align: center;
    width: 40%;
    display: flex;
    justify-content: flex-end;

    @include for-mobile {
      justify-content: center;
      min-width: 150px;
    }
    @include for-big-laptop {
      width: 100%;
      padding-top: 5px;
    }
  }
}
