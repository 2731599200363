@import "~global/styles";

.feedArticles {
  width: 100%;
  max-height: 600px;

  @include for-s-laptop-and-less {
    max-height: none;
  }

  .selection {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    height: 100%;
    margin-top: 10px;

    @include for-s-laptop-and-less {
      display: grid;
    }

    .feeds {
      min-width: 234px;
      padding: 31px 33px;
      background-color: #E0E1E2;
      border-top-left-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;

      .addFeed {
        margin-top: 40px;
        cursor: pointer;

        &:hover {
          & > * {
            color: $dark-gray;
          }
        }
      }
    }

    .articles {
      width: 100%;

      @include for-s-laptop-and-less {
        display: block;
        justify-content: center;
        overflow: hidden;
        overflow-y: auto;
        max-height: 650px;
      }
    }
  }
}
