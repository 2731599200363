.statusCell {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px !important;
  img {
    margin-right: 1rem;
  }
}

.downloadCell {
  padding-left: 2rem !important;
}
