@import "~global/styles";

.maintenanceUpgrade {

  .floorPlan {
    height: 100%;
  }

}

.greenDot {
  display: inline-block;
  margin-left: 5px;
  background-color: $primary-green;
  width: 9px;
  height: 9px;
  border-radius: 100%;
}
