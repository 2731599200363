.icon {
  fill: var(--color--primary);

  &.disabled {
    fill: #bbb9b9;
  }

  &.black {
    fill: black;
    cursor: pointer;
  }
}
