.contentContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
  margin-top: 3px;

  &.open {
    max-height: max-content;
    transition: max-height 0.2s ease;
  }

  .content {
    padding: 0 15px 15px 15px;
  }
}
