@import "~global/styles";

.fixWidth {
    margin-right: 20px;
    cursor: pointer;
}
.columns {
    flex: 1;
    text-transform: uppercase;
}
.list {
    display: flex;
    align-items: center;
    padding: 8px 15px 8px 15px;
    font-size: 16px;
    .dropdown{
        min-width: 10em !important;
    }
    .dragIcon{
        position: absolute;
        cursor: grabbing;
        right: 10px;
    }
}
