@import '~global/styles';

.weekdays{
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
    gap: 0.2em;
}

.button{
    background-color: white !important;
    border: 1px solid #e0e1e2 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 1em 2% !important;
    width: 90%;
}

.clickedButton{
    color: #0272CE !important;
    background-color: #e0e1e2 !important;
}