@import "~global/styles";

.loginForm {

  .form {
    height: fit-content;
    min-height: 350px;
    min-width: 250px;
    max-width: 400px;
    width: 400px;
    padding: 3em 1em;
    background-color: #fff;

    @include for-mobile {
      width: 100%;
    }
  }
}

.header {
  white-space: pre-line !important;
}

.strong {
  color: $primary-green;
}

.subtitle {
  margin: 20px 0;
}
