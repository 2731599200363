@import "~global/styles";

.calendar {
  background: rgb(240,240,240);
  background: linear-gradient(45deg, rgba(240,240,240,1) 10%, rgba(243,243,243,1) 15%, rgba(255,255,255,1) 27%);
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: bold;
  height:72px;
  overflow: hidden;
  text-align: center;
  width: 72px;
  display: flex;
  flex-direction: column;

  .header {
    background-color: $primary-green;
    padding: 0.1rem;
    color: #fff;
  }

  .body {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
}
