@import '~global/styles';

.upgrade {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  background-color: var(--color--gray-bg);
  box-shadow: 3px 3px 6px $light-gray;
  margin: 1rem 0;
  .icon {
    width: 20%;
    @include for-mobile {
      display: none;
    }
    @include for-s-laptop-and-more {
      width: 90px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    width: 60%;
    @include for-mobile {
      width: 80%;
    }
    .inline {
      display: flex;
      align-items: baseline;
    }

    .status {
      display: flex;
      align-items: baseline;
      width: fit-content;
      border-bottom: 1px solid $blue-darker;
      cursor: pointer;

      .blue {
        color: $blue-darker;
      }

      .greenDot {
        display: inline-block;
        margin-right: 5px;
        width: 7px;
        height: 7px;
        background-color: $primary-green;
        border-radius: 50%;
      }

      .yellowDot {
        display: inline-block;
        margin-right: 5px;
        width: 7px;
        height: 7px;
        background-color: #f4e100;
        border-radius: 50%;
      }

      .redDot {
        display: inline-block;
        margin-right: 5px;
        width: 7px;
        height: 7px;
        background-color: red;
        border-radius: 50%;
      }
    }
  }
}
