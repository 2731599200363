@import "~global/styles";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &.fixed {
    position: absolute;
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  margin: 0!important;
  width: 100%!important;
  height: 150px !important;
  padding: 0 80px!important;

  &.transparent {
    background: transparent!important;
    box-shadow: none!important;
    border: none!important;
  }

  @include for-tablet-and-less {
    height: 100px !important;
    padding: 0 40px!important;
  }

  @include for-mobile {
    padding: 0 20px!important;
  }

  .logo {
    width: 250px !important;
    margin-bottom: 25px;

    img {
      max-width: 250px !important;
    }

    @include for-tablet-and-less {
      width: 120px !important;
      margin-bottom: 20px;
    }

    @include for-mobile {
      width: 100px !important;
      margin-bottom: 10px;
    }
  }
}
