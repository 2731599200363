@import "~global/styles";

.documentList {
  display: flex; 
  flex-wrap: wrap;
  grid-column-gap: 15px;
  grid-row-gap: 38px;
  overflow-y: auto;
  max-height: 550px;
  
  @include for-s-laptop-and-less {
    max-height: none;
  }
}
