@import "~global/styles";
.container{
    align-items: center;
    height: 100vh;
}

.content{
    background-color: rgb(255 255 255 / 80%);
    border-radius: 10px;
    padding: 5% 30%;
    @include for-mobile {
        padding: 5% 15%;
    }
}

.button {
    display: flex;
    justify-content: center;
    padding: 5% 0;
}

