@import "~global/styles";

.feedPreview {
  margin-left: -2em;
  margin-right: -2em;
  height: 150px;
  max-width: 274px;
  overflow: auto;
}

.noFeeds {
  display: grid;
  align-items: center;
  height: 100%;
}
