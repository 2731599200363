.button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
}
