.icon {
  width: 16px;

  path {
    fill: white;
  }

  &.white {
    fill: white !important;
  }
}
