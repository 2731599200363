.title {
  margin-bottom: 40px !important;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 90px;
}