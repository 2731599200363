.contentContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
  margin-top: 3px;
  padding-left: 25px;

  &.open {
    max-height: 900px;
    transition: max-height 0.2s ease;
    overflow-y: auto;
  }

  .content {
    padding: 0 15px 15px 15px;
  }

  .checkList {
    padding-left: 4rem;
  }
}
