@import "~global/styles";

.button {
  border: 2px solid $primary-green !important;
  border-radius: 20px !important;
  background-color: unset !important;

  &:hover {
    background-color: #05933c44 !important;
  }
}

.title, .buttonContent{
  color: $primary-green !important;
}

