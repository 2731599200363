.nameLine {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-left: 0.5rem;
  }
}
