.monthSelector {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;

  .arrowButton {
    padding: 0.5rem;
    line-height: 0;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .monthLabel {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0 0.5rem;
  }
}
