@import "~global/styles";

.productsDetails {
    display: grid;
    grid-template-columns: repeat(auto-fit, 72px);
    grid-gap: 24px;
    max-height: 565px;
    overflow-y: auto;

    .product {
      cursor: pointer;
      margin: 5px;
    }

    .image {
      height: 72px;
      width: 72px;
      object-fit: cover;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .image:hover {
      outline: 1px solid $gray-600;
    }
  }

  .placeholder {
    margin-bottom: 100% !important;
  }
