@import "~global/styles";

.ForecastItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color--primary-text);
  font-size: $font-size-medium;
  line-height: 1.8em;

  .label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .icon {
    width: 22px;
    margin-bottom: 0.2em;
  }

  .temp {
    color: var(--color--primary);
  }

  .tempMin {

  }
}