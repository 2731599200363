.contentField {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 10px;
  width: 182px;
  background-color: var(--color--brikk-footer-bg);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    display: grid;
  }

  &.faded * {
    opacity: 0.5;
  }

  svg {
    margin-right: 17px;
    width: 30px;
  }
}
