@import "~global/styles";

.card {
  background-color: var(--color--gray-bg);
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: none;
  box-shadow: 0 5px 5px rgba(#ACB7C2, 0.2);
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 10px;

  .data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include for-mobile {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .actions {
      justify-items: flex-end;
      gap: 20px;

      @include for-mobile {
        justify-items: center;
      }
    }
  }

  .slider {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include for-mobile {
      justify-content: center;
    }
  }
}


