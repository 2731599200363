@import "~global/styles";

.internetContent{
    @include for-s-laptop-and-less {
        min-height: auto!important;
    }
}

.title{
    font-size: 75px;

    @include for-big-laptop {
        font-size: 55px;
    }

    @include for-mobile {
        font-size: 40px;
    }
}

.onboardingInternetCard{
    background-color:  #ffffff;
    opacity: 0.7;
    width: 100%;
    padding: 5%;
    margin-right: 20px;
}

.onboardingInternetHeading{
    margin-top: 30%;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow-wrap: break-word;

    @include for-s-laptop-and-less {
        margin-top: 0;
    }
}
