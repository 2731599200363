@import "~global/styles";

.image {
  margin-top: 158px;
  max-width: 338px !important;
  width: 100% !important;

  @include for-s-laptop-and-less {
    margin-top: 50px;
  }
}
