@import "~global/styles";

.WeatherCarousel {
  position: relative;
  width: 200px;

  .location {
    font-size: $font-size-medium-xs;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: var(--color--primary-text);
  }
}
