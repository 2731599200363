.numText {
  display: grid;
  grid-gap: 20px;

  .container {
    align-items: center;
    display: flex;
    justify-content: center;
    grid-gap: 15px;

    .iconWrapper {
      width: 70px;
    }
  }
}
