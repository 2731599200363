.image {
  width: 100%;

  &.hide {
    display: none;
  }

  &.circle {
    border-radius: 50%;
  }
}

.loader {
  & > * {
    width: 100% !important;
    height: 100% !important;
    max-width: none!important;
  }
}

.placeholder {
  display: block;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSI+CiAgPHBhdGggZD0iTTAgNCBMMCAyOCBMMzIgMjggTDMyIDQgeiBNNCAyNCBMMTAgMTAgTDE1IDE4IEwxOCAxNCBMMjQgMjR6IE0yNSA3IEE0IDQgMCAwIDEgMjUgMTUgQTQgNCAwIDAgMSAyNSA3Ij48L3BhdGg+Cjwvc3ZnPg==')
  no-repeat center hsl(0, 0%, 80%);
  background-size: calc(100% / 3);

  &.circle {
    border-radius: 50%;
  }
}
