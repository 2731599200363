@import "~global/styles";

.mainImage {
  display: flex;
  flex-direction: column;
}

.imageGallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 186px);
  justify-content: center;
  grid-gap: 17px;
  margin-top: auto;
  margin-bottom: 40px;
  padding: 0 5px;

  @include for-mobile {
    display: none;
  }
}
