@import "~global/styles";
.or{
    &::before{
        color: $white !important;
        background-color: $blue-darker!important;
    }
}
.button{
    background-color: $dark-gray !important;
}

.tabs{
    width: 100%;
    margin-bottom: 15px !important;
    display: flex;
    flex-wrap: wrap;
}