@import "~global/styles";

.cards {
  display: grid;
  grid-gap: 10px;

  @include for-tablet-portrait-and-less {
    grid-gap: 25px;
  }

  .cardGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    grid-gap: 10px;

    @media screen and (max-width: 600px) {
      grid-template-columns: none;
    }
  }
}

.proceedButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
