@import "~global/styles";

.Card {
  width: 260px !important;
  margin: 0 !important;

  .Content {
    padding: 1.3em 1.5em 1em !important;

    .ValueWrapper {
      display: flex !important;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
      color: $gray;

      .Icon {
        margin: 0!important;
      }

      .Value {
        margin-left: auto;
        font-size: 2.5rem;
      }

      .ValueLabel p {
        font-size: 1.5rem;
        margin-left: 0.5em !important;
      }
    }

    .Title p {
      display: block;
      font-size: 1rem  !important;
      text-transform: uppercase;
      margin-top: 1em !important;
    }

  }
}

.placeholder {
  width: 100%;
  margin-left: 60px;
}
