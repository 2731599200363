@import "~global/styles";

.onboarding {
  display: flex;
  justify-content: center;
  padding: 0 84px 50px;

  @include for-tablet-and-less {
    padding: 50px 15px;
  }
}
