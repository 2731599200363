@import "~global/styles";

.menu {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
  align-items: center;
  margin-left: auto;
  padding: 0;
  color: var(--color--header-text);

  @include for-mobile {
    grid-gap: 25px;
  }

  @include for-tablet-portrait-and-less {
    margin-left: 0;
  }

  .menuItem {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    svg {
      width: 25px;
    }
  }
  
}
