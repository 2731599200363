@import "~global/styles";

.table{
    width: 100%;
    border-spacing: 0 15px;
    text-align: center;
    vertical-align: middle;
    color: var(--color--primary-text);
    border-collapse: separate;
}

.tHeader{
    font-size: 16px;
}

.tableRow{
    background-color: var(--color--gray-bg);
    height: 4em;

    &:hover {
        cursor: pointer;
        background-color: #00000024;
    }
}
.campaign {
    height: 9em;

    td {
        border-style: solid;
        border-color: $blue;
        border-width: 3px 0;
    }
}

.first {
    border-left: 3px solid $blue !important;
}

.last {
    border-right: 3px solid $blue !important;
}

.activeRow{
    cursor: pointer;
    background-color: #00000024;
    box-shadow: 0 3px 2px rgb(18 107 208 / 13%);
}

.checkmark{
    padding-right: 25px;
}
