@import "~global/styles";

.settings {
  display: grid;
  grid-template-columns: auto 25px;
  grid-column-gap: 15px;
  align-items: center;

  * {
    color: var(--color--header-text) !important;
  }

  @include for-tablet-portrait-and-less {
    grid-template-columns: 25px;
  }

  .text {
    @include for-tablet-portrait-and-less {
      display: none;
    }
  }
}
