@import "~global/styles";

.totalCost {
  display: flex;
  justify-content: space-between;
  background-color: $primary-green;
  margin-top: auto;
  padding: 5px 10px;

  .amountLabel::after {
    content: ": ";
    white-space: pre;
  }
}
