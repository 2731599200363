@import "~global/styles";

.path {
    fill: var(--color--primary);
    cursor: pointer;

    &.disabled {
        fill: $light-gray;
        cursor: auto;
    }
}
