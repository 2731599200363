.userDetails {

  .placeholder {
    height: 30px !important;
    margin-bottom: 30px !important;

    &:before,
    &:after {
      height: 30px !important;
    }
  }
}
