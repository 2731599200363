@import "~global/styles";

.background {
    background-color: $sidebar-bg-secondary;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    height: 100px;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;
        .title {
            font-size: 20px;
            color: $white;
            font-weight: bold;
            text-transform: uppercase;
        }
        .button {
            display: flex;
            align-items: center;
            background-color: $dark-green;
            color: $white;
            padding: 20px;
            text-transform: uppercase;
            border-radius: 5px;
            cursor: pointer;
        }
        .statusText {
            color: $white;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            .blueCircle {
                width: 21px;
                height: 21px;
                border: 1px solid #eee;
                background-color: $blue-darker;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
                &.grayCircle {
                    background-color: $gray-600;
                }
            }
        }
        .customInput {
            background: transparent;
            height: 56px;
            color: $white;
            font-size: 16px;
            &:focus {
                outline: none !important;
            }
        }
    }
}
