@import "~global/styles";

.invoicesModal {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
  padding: 2rem 50px;

  @include for-tablet-and-less {
    padding: 2rem 10px;
  }

  .description {
    max-width: 550px;
    margin-bottom: 50px;
    padding-right: 7rem;

    @include for-tablet-and-less {
      padding-right: 0;
    }
  }

  .table {
    overflow: auto;
    flex-grow: 1;
  }

  .error {
    display: grid;
    justify-content: center;
    padding: 1em;
    margin: 0 auto;
  }
}

.invoicesImage {
  max-width: 300px;
  margin: 4rem auto;
}

.invoices {
  overflow: auto;
  max-height: 550px;
}
