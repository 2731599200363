@import "~global/styles";

.overlay {
  margin-top: 50px;
  padding: 24px 44px;
  width: fit-content;
  background-color: rgba(2, 114, 206, 0.8);

  @include for-mobile {
    padding: 20px;
  }

  .overlayText {
    @include for-mobile {
      font-size: 20px!important;
    }
  }
}
