@import "~global/styles";

.brikkHeader {
  align-items: center;
  display: flex;
  height: 36px;
  flex: 0 0 36px !important;
  padding: 0 6px !important;
  background-color: var(--color--brikk-header-bg) !important;
  box-shadow: var(--color--brikk-header-shadow) !important;

  &:after {
    display: none !important;
  }

  &.integratedBrikk {
    background-color: $blue-darker !important;
  }

  &.designedBrikk {
    background-color: $gray-600 !important;
  }

  .textContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    background-color: var(--color--brikk-header-icon-bg);
    padding: 3px;
    margin-right: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: var(--color--brikk-header-icons);
    }
  }

  &.svgOrderMode {
    .icon {
      svg {
        fill: white !important;
      }
    }
  }

  .heading {
    text-transform: uppercase;
    line-height: 1em;
    color: var(--color--brikk-header-text) !important;

    &.headingOrderMode {
      color: white !important;
    }
  }

  &.disabled {
    background-color: var(--color--brikk-disabled-header-bg) !important;

    .heading {
      color: var(--color--brikk-disabled-header-text) !important;
    }

    .icon {
      svg {
        fill: var(--color--brikk-disabled-header-text) !important;
      }
    }
  }
}
