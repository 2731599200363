.contactForm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 250px));
  grid-gap: 15px;

  .group {
    margin: 0 !important;

    .input {
      margin-bottom: 5px !important;
    }
  }
}

.changePass {
  width: 100%;
  margin-top: 10px !important;
}

.contactsDescription {
  margin-bottom: 20px;
}
