.subDropdown {
  padding: 0 0 0 20px;
  background-color: var(--color--gray-bg);
}

.dropdown {
  & > * {
    color: #292929 !important;
  }
}
