.statusDot {
  color: #ff0000;
  font-size: 28px;
  line-height: 16px;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 5px !important;

  &.active {
    color: #00B72B;
  }

  &.small {
    font-size: 15px;
    line-height: 8px;
  }
}
