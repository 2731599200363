@import "~global/styles";

.secondSignature {
  display: flex;
  flex-direction: column;

  .description {
    padding: 10px 9rem 10px 10px;
    @include for-mobile {
      padding: 10px;
    }
  }

  .action {
    padding: 10px 2rem 10px 10px;
    @include for-mobile {
      padding: 10px;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    }

    input {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      width: 250px;
      @include for-mobile {
        width: 100%;
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:focus {
        outline: none;
      }
    }
  }
}
