@import "~global/styles";

.circledArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 6px;
  background-color: $primary-green;

  &.dark {
    background-color: #3A3A3A;
  }

  &.light {
    background-color: transparent;
    opacity: 0.75;
  }
}
