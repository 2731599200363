@import "~global/styles";

.avatar {
  position: relative;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;

    .avatarImage {
      width: 250px;
      height: 250px;
      padding: 0!important;
      object-fit: cover;
    }

    .placeholder {
      width: 100%;
      height: 100%;
    }
  }

  .changeAvatar {
    display: grid;
    justify-content: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
    cursor: pointer;

    .text {
      display: none;
      color: $text-dark !important;
    }

    .input {
      visibility: hidden;
      display: none;
    }
  }

  &:hover {
    .changeAvatar {
      background-color: rgba(255, 255, 255, 0.6);

      .text {
        display: block;
      }

      .input {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
