@import "~global/styles";

.framed {
  border-radius: $border-radius-medium;

  img {
    height: auto;
    width: 100%;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.frejaeIDLogo {
  width: 75px;
  height: 75px;
  object-fit: contain;
}
