.closeButton{
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.image{
  width: 70%;
  margin: 0 15%;
}
