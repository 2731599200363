@import "~global/styles";

.productImages {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include for-mobile {
    flex-direction: column;
  }

  .desktopArrow {
    align-self: center;

    @include for-mobile {
      display: none;
    }
  }

  .mobileArrows {
    display: none;
    margin: auto;
    margin-top: 20px;

    @include for-mobile {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  .iconContainer {
    display: grid;
    justify-items: center;

    .icon {
      font-size: 4rem;
      color: #888888;
    }
  }

  .imagesContainer {
    display: grid;
    grid-row: auto;
    max-width: 676px;
    width: 100%;
    margin: 0 20px;

    @include for-mobile {
      margin: 0;
    }

    .selectedImage {
      max-width: 676px;
      height: 676px;
      width: 100%;
      object-fit: cover;
      border: 1px solid rgba(0, 0, 0, 0.1);

      @include for-small-laptop-and-less {
        height: 636px;
      }

      @include for-s-laptop-and-less {
        height: 400px;
      }

      @include for-tablet-portrait-and-less {
        height: 300px;
      }
    }

    .imageSelector {
      display: grid;
      grid-template-columns: repeat(auto-fill, 80px);
      grid-gap: 10px;
      margin-top: 20px;
      overflow-y: hidden;
      min-height: 80px;

      .imageOptions {
        width: 80px;
        height: 80px;
        cursor: pointer;
        object-fit: cover;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:hover {
          outline: 1px solid $gray-600;
        }
      }
    }
  }
}
