@import '~global/styles';

.text {
  color: var(--color--primary-text);
  font-family: $font-family;
  font-size: $font-size-medium-xs;
  white-space: pre-line;

  &.small {
    font-size: $font-size-small;
  }

  &.dark {
    color: $text-dark !important;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.breakAll {
    word-break: break-all;
  }

  &.underline {
    text-decoration: underline;
  }

  &.bold {
    font-weight: $font-weight-bold;
  }

  &.italic {
    font-style: italic;
  }
  &.lightGray {
    color: #979fb8 !important;
  }

  &.brown {
    color: #af7717 !important;
  }

  &.xs {
    font-size: $font-size-xs;
  }

  &.large {
    font-size: $font-size-large;
  }

  &.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.xl {
    font-size: $font-size-xl;
  }

  &.xxl1 {
    font-size: $font-size-xxl-1;
  }

  &.xxl2 {
    font-size: $font-size-heading;
  }

  &.xxl3 {
    font-size: $font-size-xxl-3;
  }

  &.xxl4 {
    font-size: $font-size-xxl-4;
  }

  &.xxl5 {
    font-size: $font-size-xxl-5;
  }

  &.primary {
    color: var(--color--primary) !important;
  }

  &.error {
    color: #a52a2a !important;
  }

  &.white {
    color: #fff !important;
  }

  &.thin {
    font-weight: $font-weight-thin;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.clickable {
    cursor: pointer;
  }

  &.medium {
    font-size: $font-size-medium;
  }

  &.mediumxs {
    font-size: $font-size-medium-xs;
  }

  &.red {
    color: #b41111 !important;
  }
}
