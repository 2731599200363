.bankIDPersonalNumber {
  display: grid;
  grid-row-gap: 20px;
  max-width: 500px;
  width: 100%;

  .logo {
    justify-self: center;
  }

  .button{
    display: flex;
    justify-content: center;
  }
}
