@import "~global/styles";

.outerLayer {
  position: fixed;
  top: 0;
  left: 0;
  justify-items: center;
  align-items: center;
  padding: 1rem;
  display: grid;
  height: 100%;
  width: 100%;
  overflow-y:auto;
  overflow-x:hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .innerModal {
    width: fit-content;
    height: fit-content;
    padding: 1em;
    background-color: white;
  }
}
