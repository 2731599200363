@import "~global/styles";

.information {
    width: 100%;
    padding: 20px;
    background-color: var(--color--gray-bg);
    margin: 1rem 0rem 1rem 0;
}

.label {
    padding-bottom: 5px;
}

.signParagraph {
    padding-top: 17px;
    padding-bottom: 17px;
}

.blue {
    color: $blue-darker;
}
