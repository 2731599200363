@import "~global/styles";

.card{
  background-color: var(--color--gray-bg)!important;
  width: 100%!important;
  overflow: auto;
  margin: 10px;
}

.temperatureDisplay{
  padding: 2em 0;
}

.temperatureDisplayValue{
  @include for-mobile {
    font-size: 50px!important;
  }
}

.temperatureDisplayValueSmall{
  @include for-mobile {
    font-size: 10px!important;
  }
}
