@import "~global/styles";

.sideMenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1000;

  &.open {
    width: 100vw;
    height: 100vh;
  }

  .sideMenu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 0;
    min-width: 0;
    width: 0;
    height: 100vh;
    background-color: $sidebar-bg-secondary;
    transition: max-width 0.2s ease-in, min-width 0.2s ease-in;
    overflow-y: auto;

    &.open {
      min-width: 350px;
      max-width: 350px;
      width: auto;
      transition: max-width 0.2s ease-out, min-width 0.2s ease-out;
    }

    .header {
      display: grid;
      justify-items: end;
      padding: 21px;
      background-color: $sidebar-bg-secondary;
    }

    .content {
      background-color: $sidebar-bg;
    }
  }
}
