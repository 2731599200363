.menu {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;

  .menuItem {
    padding: 10px;
    cursor: pointer;

    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
      outline: none;
    }
  }
}