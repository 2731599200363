.downloadBadges {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  grid-gap: 20px;

  .icon {
    width: 215px;
  }

  .iconAndroid {
    width: 190px;
  }
}
