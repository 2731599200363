.WeekForecast {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .heading {
    margin-bottom: 2em !important;
  }

  .itemsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
