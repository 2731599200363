@import '~global/styles';

.range {
  width: 130px !important;

  @include for-mobile {
    padding: 10px 0;
  }
}

.thumbMax{
  transform: translate(109px, 0px) !important;
}

.thumbMin{
  transform: translate(0, 0) !important;
}
