.card{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none !important;
}

.active {
    box-shadow: 0 3px 2px rgba(#aeadad, 0.4) !important;
    background-color: #ededed;
}
