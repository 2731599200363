@import "~global/styles";

.sideMenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1000;

  &.open {
    width: 100vw;
    height: 100vh;
  }

  .sideMenu {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 0;
    min-width: 0;
    width: 0;
    height: 100vh;
    padding-bottom: 50px;
    background-color: $sidebar-bg;
    transition: max-width 0.2s ease-in, min-width 0.2s ease-in;
    overflow-y: auto;

    &.open {
      min-width: 350px;
      max-width: 350px;
      width: auto;
      transition: max-width 0.2s ease-out, min-width 0.2s ease-out;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 15px #{$side-menu-padding-right - 10};
      color: white;
    }

    .settings {
      display: grid;
      grid-gap: 30px;
      margin-top: 20px;
      padding-right: 0;
    }
  }
}
