@import "../../../assets/styles/variables";

.loader {
  margin: auto;
  width: 54px;
  height: 18px;

  .ball {
    width: 10px;
    height: 10px;
    margin: 4px;
    display: inline-block;
    background-color: var(--color--primary);
    border-radius: 100%;
    opacity: 0;
    animation: ball-fall 1s ease-in-out infinite;
  }

  .ball:nth-child(1) {
    animation-delay: -200ms;
  }

  .ball:nth-child(2) {
    animation-delay: -100ms;
  }

  .ball:nth-child(3) {
    animation-delay: -0ms;
  }
}

@keyframes ball-fall {
  0% {
    opacity: 0;
    transform: translateY(-145%);
  }
  10% {
    opacity: .5;
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: .5;
  }
  100% {
    opacity: 0;
    transform: translateY(145%);
  }
}
