.step {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 177px;
  padding: 7px;
  background-color: var(--color--primary-bg);
  box-shadow: 0 3px 2px rgba(#aeadad, 0.4);
}
