@import "~global/styles";

.content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.weatherSlide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .valueWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 0.2em;
    font-weight: bold;
    color: #303849;
    line-height: 1em;

    .unit {
      padding-top: 0.3em;
      font-size: 0.5em;
      line-height: 1em;
    }
  }

  &.small {
    .valueWrapper {
      font-size:  $font-size-medium;
    }
  }

  &.medium {
    .valueWrapper {
      font-size: $font-size-xxl-5;
    }
  }

  &.large {
    width: 100% !important;
    .valueWrapper {
      font-size: 110px;
    }
  }

  &.blue {
   .valueWrapper, .unit {
      color: #0272CE;
    }
  }
}
