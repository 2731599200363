@use "sass:math";
@import "~global/styles";

.themeSettings {
  text-align: right;

  .headingWrapper {
    background-color: $sidebar-bg-secondary;
    padding: math.div($side-menu-padding-right, 5) $side-menu-padding-right;

    .heading {
      padding-right: 0.7em;
      border-right: 3px solid var(--color--primary);
    }
  }

  .settings {
    display: grid;
    justify-items: end;
    margin-top: 22px;
    padding: 0 $side-menu-padding-right;

    .checkbox {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 25px;
      padding: 0.6rem 0;
      justify-content: end;
      align-items: center;
      border-bottom: 1px solid #292828;
    }
  }
}
