.reactPopper {
  z-index: 10 !important;
}

.datePicker {
  :global .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }

  :global .react-datepicker__day-name {
    color: #bebebe;
  }
}

.picker{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-right: 10px;
}
