@import "~global/styles";

.productDetails {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 70px;
  padding: 10px 0;

  @include for-s-laptop-and-less {
    grid-template-columns: none;
  }

  .productContent {
    display: flex;
    flex-direction: column;

    .heading {
      margin-bottom: 40px !important;
    }

    .productName {
      margin-bottom: 25px !important;
    }
  }
}
