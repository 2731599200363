@import "~global/styles";

.menu {
  display: grid !important;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 40px;
  align-items: center;
  text-transform: uppercase !important;

  @include for-mobile {
    grid-column-gap: 25px;
  }

  .arrangeBrikks {
    text-transform: uppercase !important;

    svg {
      margin-left: 10px;

      @include for-mobile {
        margin-left: 0;
      }
    }
  }

  .dropdown {
    display: grid;
    grid-column-gap: 10px;
    align-items: center;

    @include for-mobile {
      display: inline-block;
    }
  }

  .userMenu {

    .userText {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 10px;
      align-items: center;
    }

    .avatar {
      width: 40px;
      max-width: 40px!important;
      background: $primary-green;
    }
  }

  .link {
    padding: 0!important;
  }

  @include for-tablet-portrait-and-less {
    .menuText {
      display: none !important;
    }
  }
}
