@import "~global/styles";

.emailLogin {
  display: grid;
  grid-row-gap: 20px;

  .field {
    margin: 0!important;
  }

  .login {
    width: fit-content !important;
    background-color: $primary-green !important;
    color: #fff !important;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
