.hamburgerMenu {
  width: 17px;
  cursor: pointer;
}

.hamburgerMenu rect {
  fill: var(--color--header-icons);
}

.clickableIcon {
  &:focus {
    border-radius: 0 !important;
    background-color: transparent !important;
  }
}
