@import "~global/styles";

$grid-gap: 12px;

.form {
  display: grid;
  grid-gap: $grid-gap;

  .equalColumns {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $grid-gap;
  }

  .smallBigColumn {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: $grid-gap;
  }
}

.formButtons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 15px;
  width: 100%;

  .changeButton {
    justify-self: start;
  }
}

.properties {
  display: grid;
  grid-gap: 15px;

  .group {
    display: flex;
    align-items: center;

  }
}

@include for-tablet-and-less {
  .label {
    min-height: 50px;
  }
}

@include for-mobile {
  .label {
    min-height: 60px;
  }
}
