@import "~global/styles";

.agreement {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: var(--color--gray-bg);
    box-shadow: 5px 5px 5px rgba(#acb7c2, 0.25);
    margin: 1rem 0rem 1rem 0;
    user-select: none;

    &.pointer {
        cursor: pointer;
    }

    .text {
        display: flex;
        flex-direction: column;

        .inline {
            display: flex;
            align-items: baseline;
            .statusIcon{
                display: inline-block;
                margin-left: 5px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                &.active{
                  background-color: $primary-green;
                }
                &.pending{
                  background-color: orange;
                }
                &.inactive{
                  background-color: red;
                }
            }
            .space {
                white-space: pre;
            }
            .paddingRight {
                padding-right: 3px;
            }
        }
    }

    .statusIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
