@import "~global/styles";

.icon {
  &.deactive {
    cursor: auto;
  }
  .path {
    fill: var(--color--header-icons);
    &.disabled {
      fill: $light-gray;
    }
  }

  &.active {
    filter: brightness(120%);
  }
}
