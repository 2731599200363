@import "~global/styles";

.clickableIcon {
  outline: none;

  &:focus {
    border-radius: 100%;
    background-color: var(--color--primary-text);
    transition: background-color 0.2s ease;

    svg, path {
      fill: var(--color--primary-bg);
    }

    &.ignoreTheme {
      background-color: #fff;

      svg, path {
        fill: $sidebar-bg-secondary;
      }
    }
  }
}
