.documentPreview {
  display: grid;
  width: 100%;
  // max-width: 235px;
  min-width: 161px;
  height: 250px;
  padding: 12px 16px;
  background-color: var(--color--gray-bg);
  box-shadow: 0 3px 6px #00000029;
}
