.avtalModal {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
  padding: 2rem 10px;

  .description {
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
  }

  .error {
    display: grid;
    justify-content: center;
    padding: 1em;
    margin: 0 auto;
  }
}

.table {
  overflow: auto;
  max-height: 550px;
}
