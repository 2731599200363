@import "~global/styles";

.checkout{
  .items {
    min-height: 150px;
    margin-bottom: 10px;

    .item {
      display: flex;
      justify-content: space-between;
      @include for-mobile {
        flex-direction: column;
        margin-bottom: 10px;
      }

      .value {
        display: flex;
        flex-direction: row;
        .price{
          padding-right: 5px;
        }
        @include for-mobile {
         justify-content: space-between;
        }
      }
    }
  }

  .payment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    background-color: #00B72B;
    margin-top: 10px;
  }
}
