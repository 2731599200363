.brikkFooter {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 30px;
  padding: 0 0.5em !important;
  background-color: var(--color--brikk-footer-bg) !important;

  &:after {
    display: none !important;
  }
}
