.item {
  padding: 6px 0;
  text-align: center;
  border-right: 1px solid var(--color--header-menu-border);

  & > * {
    color: var(--color--header-grid-text) !important;
  }

  &:hover {
    background-color: var(--color--header-menu-selected-bg);
    color: var(--color--header-menu-selected-text);

    p {
      color: var(--color--header-menu-selected-text) !important;
    }
  }

  &.active {
    background-color: var(--color--header-menu-selected-bg);
    color: var(--color--header-menu-selected-text);
    cursor: default;

    p {
      color: var(--color--header-menu-selected-text) !important;
    }
  }
}
