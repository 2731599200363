@import "~global/styles";

.orderMode {
  display: grid;
  grid-template-columns: auto 25px;
  grid-column-gap: 15px;
  align-items: center;

  * {
    color: var(--color--header-text) !important;
  }

  @include for-tablet-portrait-and-less {
    grid-template-columns: 25px;
  }

  .icon {
    color: var(--color--header-icons) !important;
  }

  .activeIcon{
    color: $dark-green !important;
  }

  .text {
    @include for-tablet-portrait-and-less {
      display: none;
    }
  }
}
