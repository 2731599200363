@import "~global/styles";

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 33px;
  padding: 68px 62px;

  &.noPadding{
    padding: 0px;
  }

  &.noTopPadding {
    padding: 0 62px;
    position: relative;

    @include for-s-laptop-and-less {
      padding: 0 10px;
    }
  }

  &.oneColumn{
    grid-template-columns: 1fr;
    width: 100%;

    @include for-tablet-portrait-and-less {
      grid-template-columns: 100%;
    }
  }

  &.twoEqualColumns{
    grid-template-columns: 1fr 1fr;
    width: 100%;

    @include for-tablet-portrait-and-less {
      grid-template-columns: 100%;
    }
  }

  &.twoColumns {
    grid-template-columns: 1fr 2fr;

    @include for-s-laptop-and-less {
      grid-template-columns: 100%;
    }
  }

  &.paddingTop {
    padding-top: 60px;

    @include for-s-laptop-and-less {
      padding-top: 20px;
    }
  }

  &.transparent {
    position: relative;
    grid-template-columns: 53% 42%;
    grid-gap: 66px;

    @include for-s-laptop-and-less {
      grid-template-columns: none;
    }
  }

  & {
    @include for-s-laptop-and-less {
      grid-template-columns: none;
      grid-gap: 50px;
      justify-items: center;
      padding: 30px 10px 100px;
    }
  }
}
