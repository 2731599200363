.UpgradeItems {
  overflow-y: auto;

  .roomTypes {
    padding: 0.2em 0;
  }

  & > * {
    margin-bottom: 0.2em;
  }
}
