@import "~global/styles";

.tooltip {
  position: relative;
  z-index: 200;

  .element {
    cursor: pointer;
  }

  .content {
    position: absolute;
    top: -10px;
    right: 20px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    transition: opacity ease-in-out 0.2s;
    opacity: 0;
    pointer-events: none;
    z-index: 200;

    @include for-tablet-portrait-and-less {
      left: auto;
      right: 20px;
    }
  }

  &:hover {
    .content {
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
      pointer-events: auto;
    }
  }
}
