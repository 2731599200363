@import "~global/styles";

.innerModal {
  min-height: 894px;
  width: 100%;
  border-radius: $border-radius-big !important;

  @include for-small-laptop-and-less {
    min-height: 844px;
  }

  .header {
    padding: 15px !important;

    .closeButton {
      align-self: start;
      display: grid;
      justify-items: end;
    }
  }

  .content {
    padding: 0 75px;

    @include for-s-laptop-and-less {
      padding: 0 10px 40px;
    }
  }
}
