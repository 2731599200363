.icon {
  width: 36px;

  .hole {
    fill: none;
  }

  .info {
    fill: #2185d0;

    &.gray {
      fill: #767676;
    }
  }
}
