@import "~global/styles";

.icon {
  width: 50px;

  &.up {
    transform: rotate(-45deg);
  }

  &.down {
    transform: rotate(45deg);
  }
}
