@import "~global/styles";

.heading {
  color: $text-dark;
  font-size: $font-size-heading;
  font-weight: $font-weight-bold;
  white-space: pre-line;

  &.xxl {
    font-size: $font-size-xxl;
  }

  &.xxl1 {
    font-size: $font-size-xxl-4;
  }

  &.gray {
    color: $gray !important;
  }

  &.xxl3 {
    font-size: $font-size-xxl-3;
  }

  &.normal {
    font-weight: $font-weight-normal;
  }

  &.dark {
    color: $text-dark !important;
  }

  &.red {
    color: #b41111 !important;
  }
}
