@import "~global/styles";

.button {
  width: fit-content !important;
  background-color: $primary-green !important;
  color: #fff !important;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}