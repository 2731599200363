@import "~global/styles";

.column {
  width: 100%;
  padding: 0 10px;
  background-color: var(--color--secondary-bg);

  &.fullHeight {
    height: 100%;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &:first-child {
    z-index: 3;
  }

  &:nth-child(2) {
    z-index: 2;
  }

  &:last-child {
    z-index: 1;
  }

  &.brikkLayout {
    padding: 16px;
    min-height: 710px;
    max-height: 710px;
    overflow-y: auto;
    overflow-x: hidden;

    @include for-small-laptop-and-less {
      min-height: 660px;
      max-height: 660px;
    }

    @include for-s-laptop-and-less {
      min-height: auto;
      max-height: none;

      padding-bottom: 4rem !important;
    }

  }

  &.transparent {
    display: grid;
    background-color: transparent !important;
    padding: 0;

    @include for-small-laptop-and-less {
      min-height: 660px;
      max-height: 660px;
    }

    @include for-s-laptop-and-less {
      max-height: 800px;
    }

    @include for-mobile {
      min-height: auto;
      max-height: none;
    }
  }

  &.twoRow {
    display: grid;
    grid-gap: 30px;
    background-color: transparent !important;
    padding: 0;
    overflow-y: hidden;

    & > * {
      padding: 16px;
    }
  }

  &.twoColumns {
    &:last-child {

      @include for-s-laptop-and-less {
        & {
          overflow-x: auto;
        }
      }
    }
  }

  &.noOverflow {
    overflow: hidden;
  }
}
