.bankIDSigning {
  display: flex;
  flex-direction: column;

  .sign {
    display: flex;

    .bankIDLogo {
      width: 54px;
      margin: 0 2rem 1rem 1rem;
    }

    .bankIDTextContainer {
      background-color: #EEEEEE;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
      width: 60%;
      height: 54px;
      padding-top: 16px;
      padding-left: 20px;

      .bankIDText {
        cursor: pointer;
        width: fit-content;
      }

      .disabled {
        cursor: auto;
      }
    }
  }
}
