@import "~global/styles";

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  color: var(--color--header-text);
  background-color: var(--color--header-bg);
  font-size: $font-size-medium-xs;

  &.fixed {
    position: initial;
    background-color: transparent!important;
  }

  .hamburgerMenu {
    display: none;
    margin-right: 30px;

    @include for-s-laptop-and-less {
      display: block;
    }

    @include for-tablet-portrait-and-less {
      margin-right: 0;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    padding: 50px 84px;
    height: 70px;

    @include for-s-laptop-and-less {
      padding: 20px;
    }

    @include for-tablet-portrait-and-less {
      justify-content: space-between;
    }
  }
  .card {
    border: none;
  }
}

