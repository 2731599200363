@use "sass:math";
@import "~global/styles";

.languageSettings {
  text-align: right;

  .headingWrapper {
    background-color: $sidebar-bg-secondary;
    padding: math.div($side-menu-padding-right, 5) $side-menu-padding-right;

    .heading {
      padding-right: 0.7em;
      border-right: 3px solid var(--color--primary);
    }
  }
}
