@import "~global/styles";

.button {
  width: fit-content !important;
  background-color: transparent !important;
  padding: 0.2em 0.5em !important;
  margin: 0.2em;
  text-decoration: underline !important;
  font-weight: normal !important;
  font-size: 12px !important;
  color: var(--color--brikk-footer-text) !important;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}
