@import "~global/styles";

.contentImage {
  width: 150px;
  height: 150px;
}

.tooltip {
  width: 310px;
  font-size: $font-size-medium !important;
  text-align: center;
  margin-top: 0!important;
}
