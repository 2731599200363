@import "~global/styles";

.menu {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 9px;
  align-items: center;
  min-width: 195px;
  padding: 10px 13px;
  background-color: white;
  border: 1px solid $gray-200;
  border-radius: 4px;

  & {
    @include for-s-laptop-and-less {
      grid-template-columns: none;
      grid-template-rows: 30px auto;
      grid-gap: 5px;
      justify-content: center;
      min-width: fit-content;
      border: none;
    }
  }

  .text {
    display: grid;
    grid-row-gap: 5px;

    .description {
      @include for-s-laptop-and-less {
        display: none;
      }
    }
  }

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;

    &.active {
      svg path, circle {
        fill: $primary-green !important;
      }
    }
  }
}
