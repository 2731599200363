.icon {
  width: 33px;

  .background {
    fill:#1a237b
  }

  .white {
    fill:#fff
  }

  .line {
    fill:#bd0034
  }
}
