@import "~global/styles";

.dropdownButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color--gray-bg);
  color: var(--color--primary-text);
  padding: 11px 20px;
  cursor: pointer;

  .dropdownAction{
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkList {
      background: none;
      padding-left: 0; 
    }
  }

  &.active {
    background-color: var(--color--primary);
    color: $white;
    font-weight: bold; 


    & > * {
      color: white !important;
    }
  }
}
