.emptyPlaceholder {
  display: grid;
  align-items: center;
  justify-content: center;
}

.maintenanceCost {
  display: grid;
  height: 100%;
  align-items: start;
  overflow-y: auto;
  overflow-x: hidden;

  .addedItems {
    display: grid;
    grid-gap: 10px;
    margin-bottom: 30px;

    .addedItem {
      display: flex;
      justify-content: space-between;

      .itemName {
        margin-right: 15px!important;
      }

      .costAndRemove {
        display: grid;
        grid-template-columns: 60px auto;
        grid-gap: 25px;
        align-items: center;
        text-align: right;
      }
    }
  }
}
