@use "sass:math";
@import "~global/styles";

.backgroundSettings {
  text-align: right;

  .headingWrapper {
    background-color: $sidebar-bg-secondary;
    padding: math.div($side-menu-padding-right, 5) $side-menu-padding-right;

    .heading {
      padding-right: 0.7em;
      border-right: 3px solid var(--color--primary);
    }
  }

  .settings {
    display: grid;
    justify-items: end;
    grid-template-columns: 50% 50%;
    grid-template-rows: 80px 80px;
    grid-row-gap: 22px;
    grid-column-gap: 16px;
    margin-top: 22px;
    padding: 0 $side-menu-padding-right;
    width: 100%;

    .bgImage {
      width: 100%;
      height: 100%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.active {
        padding: 3px;
        border: 3px solid var(--color--primary);
      }
    }
  }
}
