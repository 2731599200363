@import "~global/styles";

.modalCard {
  width: 100%;
  min-height: 894px;
  padding-bottom: 80px;
  background-color: var(--color--primary-bg);

  @include for-small-laptop-and-less {
    min-height: auto;
  }

  @include for-mobile {
    min-width: fit-content;
  }
}
