@import "~global/styles";

.ticket {
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(#ACB7C2, 0.2);
  display: flex;
  flex-direction: row;
  margin: 1rem 1rem 1rem 0;
  padding: 10px 18px 25px;
  position: relative;
  word-break: break-word;

  @include for-mobile {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
  }

  .calendar {
    margin-right: 2rem;
  }

  .text {

    .ticketId {
      font-size: $font-size-xl;
      padding-right: 30px; // to wrap the .statusIcon
    }

    .date {
      font-size: $font-size-xl;
    }

    .status {
      font-size: $font-size-xl;
      margin-bottom: 1.4em;
    }

    .title {
      font-size: $font-size-large;
      margin-bottom: 1.4em;
    }

    .description {
      font-size: $font-size-large;
    }
  }

  .statusIcon {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
