@use "sass:math";
@import "~global/styles";

.itemContent {
  $dotSize: 8px;
  padding: 10px;
  background-color: var(--color--secondary-bg);

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .surfaceDetailsTable {
      width: 100%;
      overflow-y: auto;
      margin-bottom: 10px;

      .info {
        display: flex;

        .label::after {
          content: ": ";
          white-space: pre;
        }
      }
    }
  }

  .selectButton,
  .selectedLabel {
    margin-left: auto !important;
  }

  .selectedLabel {
    font-weight: bold;
    color: $primary-green;
  }

  .additionalInfo {
    margin-top: 10px !important;
  }

  .unitName {
    padding-left: $dotSize * 1.5;
  }

  .greenDot {
    background-color: $primary-green;
    border: 1px solid var(--color--gray-bg);
    border-radius: 50%;
    box-sizing: content-box;
    display: inline-block;
    height: $dotSize;
    margin-left: -$dotSize * 1.5;
    margin-right: math.div($dotSize, 4);
    width: $dotSize;
  }
}
