@import "~global/styles";

.subtitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form {
  height: fit-content;
  max-width: 400px;
  width: 400px;
  @include for-mobile {
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}