@import "~global/styles";

.chartWrapper {

  .chartBox {
    background-color: #fff;
    padding: 1rem;

    @include for-tablet-portrait-and-less {
      padding: 0.5rem;
    }
  }

  .filtersWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    @include for-mobile {
      justify-content: center;
    }
  }

  .buttonGroup {
    display: flex !important;
    flex-wrap: wrap;
    margin-top: 0.2rem !important;

    @include for-mobile {
      flex-direction: column !important;
      margin: 0.2rem auto;
    }

    .button {
      font-weight: bold !important;
      width: 150px;
      border: 1px solid #ccc;

      @include for-tablet-portrait-and-less {
        width: auto;
      }
    }
  }
}
