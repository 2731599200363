@import "~global/styles";

.WeatherSlide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;

  .ValueWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 0.2em;
    font-weight: bold;
    color: var(--color--primary);

    .Value {
      line-height: 1em;
    }

    .ValuePrefix {
      padding-top: 0.3em;
      font-size: 0.5em;
      line-height: 1em;
    }
  }

  .LabelWrapper {
    margin-top: 1em;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color--primary-text);
  }

  &.small {

    .icon {
      width: 50px;
      height: 50px;
    }

    .ValueWrapper {
      font-size: $font-size-xxl-4;
    }

    .LabelWrapper {
      font-size: $font-size-medium-xs;
    }
  }

  &.medium {

    .icon {
      width: 60px;
      height: 60px;
    }

    .ValueWrapper {
      font-size: $font-size-xxl-5;
    }

    .LabelWrapper {
      font-size: $font-size-xxl;
    }
  }

  &.large {

    .icon {
      width: 120px;
      height: 120px;
    }

    .ValueWrapper {
      font-size: $font-size-xxl-6;
    }

    .LabelWrapper {
      font-size: $font-size-heading;
    }
  }
}
