@import "~global/styles";

.toggleSwitch {
  position: relative;
  width: fit-content;

  .option {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    text-align: center;
    font-weight: 600;
    cursor: pointer;

    &.active {
      background-color: $primary-green;
    }
  }
}
