@import "~global/styles";

.modal {
  border-radius: 20px !important;
  background-color: var(--color--primary-bg);
  backdrop-filter: blur(10px);

  .header {
    display: grid !important;
    align-items: center;
    border: none!important;
    padding: 15px!important;

    .discard {
      align-self: start;
      display: grid;
      justify-items: end;
    }

  }

  .header,
  .content {
    position: relative;
    background: transparent !important;
  }
}
