@import "~global/styles";

.multiGrid {
  display: grid;
  grid-auto-flow: column;
  border-top: 1px solid var(--color--header-menu-border);
  overflow-y: auto;
  background-color: var(--color--header-grid-bg);

  @include for-s-laptop-and-less {
    display: none;
  }
}
