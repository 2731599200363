@import "~global/styles";

.logoWrapper{
  display: flex;
}

.logo {
  width: auto;
  max-height: 55px;

  @include for-s-laptop-and-less {
    width: 150px;
  }

  @include for-mobile {
    width: 100px;
  }
}

.logoRib {
  @extend .logo;

  @include for-tablet-and-less {
    display: none;
  }
}

.verticalLine{
  border-left: 2px solid var(--color--header-logo-vertical-separator-color);
  margin-left: 10px;
  margin-right: 10px;

  @include for-tablet-and-less {
    display: none;
  }
}
