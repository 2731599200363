@import "~global/styles";

.icon {
  fill: var(--color--primary-text);

  &.active {
    fill: var(--color--primary);
  }

  &.activeGreen {
    fill: $primary-green;
  }

  &.activeGrey {
    fill: $dark-gray;
  }
}

