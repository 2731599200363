.downloadSectionImage {
  width: 224px;
  display: block;
  margin: 0 auto;
  padding-top: 30px;
}

.downloadBadges {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  grid-gap: 20px;

  .icon {
    width: 215px;
  }

  .iconFullWidth {
    width: 190px;
  }
}
