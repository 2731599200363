@import "~global/styles";

.content {
  text-align: center;

  .waterUsage {
    display: flex;
    align-items: baseline;

    .icon {
      display: grid;
      justify-items: center;
      grid-gap: 5px;

      &:after {
        display: block;
        content: "";
        width: 46px;
        height: 4px;
        background-color: $gray;
      }
    }
  }

  .noWaterUsage {
    margin-bottom: 15px;
  }
}
