@import "~global/styles";

.content {
  display: grid;
  width: 100%;

  .input {
    font-weight: $font-weight-bold !important;
    font-size: $font-size-large !important;
    opacity: 1!important;
    & > * {
      opacity: 1!important;
    }

    input {
      padding-top: 5px!important;
      padding-bottom: 5px!important;
      font-weight: $font-weight-bold !important;
    }

    &.disabled {
      input {
        padding-top: 0!important;
        padding-bottom: 0!important;
        border: none !important;
        margin-left: -1em !important;
        background-color: transparent !important;
      }

    }
  }
}
