.otherPhone {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    margin-left: 10% !important;
    height: 25px!important;
  }
}
