.icon {
  width: 33px;

  .background {
    fill: #2d5a95;
  }

  .lines {
    fill: #f3d02f;
  }
}
