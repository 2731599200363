.proceedButton {
  display: flex;
  flex-wrap: nowrap;
  height: 31px;
  cursor: pointer;
  outline: none;

  &.stacked {
    flex-direction: column;
    align-items: center;
    height: auto;

    > * {
      margin: 0;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  }
}
