.maintenanceOrder {
  display: grid;
  justify-items: center;
  align-items: center;
  min-height: 200px;

  .success,
  .loading,
  .error, {
    display: grid;
    justify-items: center;
    grid-gap: 20px;
  }
}
