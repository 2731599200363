@import "~global/styles";

.brikk {
  border: 2px dashed transparent;
  position: relative;

  &.dragged {
    border: 2px dashed $primary-green;
    border-radius: $border-radius-medium;

    & > * {
      opacity: 0;
    }
  }

  .card {
    width: auto !important;
    box-shadow: none !important;
  }
}
