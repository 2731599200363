@import "~global/styles";

.icon {
  cursor: pointer;
  fill: var(--color--primary);

  &.error {
    path {
      fill: $red;
    }
  }
}
