@import "~global/styles";

.imageContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  @include for-s-laptop-and-less {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }

  .image {
    background: no-repeat center;
    background-size: cover;
    border-top-left-radius: $radius-lg;
    border-bottom-left-radius: $radius-lg;;

    @include for-s-laptop-and-less {
      border-top-right-radius: $radius-lg;
      border-bottom-left-radius: 0;
    }

    @include for-mobile {
      display: none;
    }
  }

  .content {
    padding: 0 100px;
    justify-self: center;
    align-self: center;

    @include for-s-laptop-and-less {
      padding: 20px;
    }

    @include for-mobile {
      padding: 100px 20px 0;
    }
  }
}
