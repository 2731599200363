.button {
    background-color: transparent !important;
    margin: 0 !important;
    width: fit-content ;
    justify-self:right;
    float: right;
}

#deleteButton {
    padding: 0 0 0 2.5em !important;
}
