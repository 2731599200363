@import "~global/styles";

.fields {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 10px;
    cursor: pointer;

  .item {
    padding-left: 50px;
    color:  var(--color--primary-text);
  }

  .active {
    background-color: var(--color--primary);
    color: $white;
    font-weight: $font-weight-bold; 
    padding: 10px 0px 10px 50px;
  }

  &:hover {
    font-weight: $font-weight-bold !important;
    background-color: var(--color--gray-bg);
  }
}
