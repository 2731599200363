.icon {
  margin-top: 0.2em;
  width: 24px !important;
  margin-right: 12px;
}

.noInvoices {
  display: grid;
  grid-gap: 36px;
  overflow-y: auto;
  max-height: 170px;

  .rentPreview {
    opacity: 0.5;
  }

  .noData {
    color: var(--color--brikk-disabled-content-text) !important;
  }
}

.invoices {
  overflow-y: auto;
  max-height: 170px;

  .item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
