$previewHeight: 148px;

.preview {
  align-self: center;
  justify-self: center;
  width: 110px;
  height: $previewHeight;
  background-color: gray;

  & * {
    height: $previewHeight !important;
    pointer-events: none !important;
    overflow: hidden !important;
  }
}
