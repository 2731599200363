.icon {
  width: 20px;

  &.x2 {
    width: 46px;
  }

  path {
    fill: white;
  }

  &.left {
    transform: rotate(180deg);
  }

  &.down {
    transform: rotate(90deg);
  }

  &.light {
    path {
      fill: var(--color--primary-text);
    }
  }

  &.primary {
    path {
      fill: var(--color--primary);
    }
  }
}
