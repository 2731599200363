@import "~global/styles";

.navigationMenu {
  display: flex;
  justify-content: center;

  & {
    @include for-s-laptop-and-less {
      width: 100vw;
      background-color: white;
    }
  }

  &.fixed {
    @include modalAbsoluteFooter(null);
    transform: translate(-50%, 0);
    left: 50%;

    @include for-s-laptop-and-less {
      display: none;
    }
  }
}
