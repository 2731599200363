.modalDescription {
  max-width: 400px;
  margin-bottom: 50px;

  .description {
    display: grid;
    grid-row-gap: 10px;
  }

  .image {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
}
