.subtitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
