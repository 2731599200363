@import "~global/styles";

.info {
  display: flex;

  .label::after {
    content: ": ";
    white-space: pre;
  }
}
