@import "~global/styles";

.icon {
  cursor: pointer;

  .discard {
    fill: var(--color--primary-text);
  }

  &.white {
    .discard {
      fill: white;
    }
  }
}
