@import "~global/styles";

.orderList {
    display: flex;
    background-color: $gray-200;
    margin-left: auto;
    margin-right: auto;
    width: 95vw;

    .container {
        display: flex;
        width: 100%;
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;

        .left {
            flex: 2;
            .fixWidth {
                width: 40px;
            }
            .columns {
                flex: 1;
                text-transform: uppercase;
            }
            .header {
                display: flex;
                border-bottom: 1px solid $gray;
                padding: 0px 15px 10px 48.5px;
                margin-bottom: 10px;
                font-size: 16px;
            }
            .list {
                display: flex;
                align-items: center;
                border-bottom: 0.5px solid grey;
                padding-top: 30px;
                padding-bottom: 30px;
                font-size: 16px;
            }
        }
        .right {
            flex: 1;
            padding: 20px;

            .rightContainer {
                padding: 30px;
                background-color: $white;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .successText {
                    color: #1a531b;
                }

                .errorText {
                    color: #912d2b;
                }

                .title {
                    font-size: 25px;
                    padding-bottom: 33px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .greenBtn {
                    width: 100%;
                    background-color: $dark-green;
                    margin-top: 20px;
                    padding: 16px 77px 16px 77px;
                    border: transparent;
                    color: $white;
                    text-transform: uppercase;
                    border-radius: 5px;
                }
            }
        }
    }
}
