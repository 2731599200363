.documentTitle {
  display: grid;
  grid-template-columns: 24px auto;
  grid-gap: 23px;
  align-items: center;

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }
}
